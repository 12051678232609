<template>
  <section id="praticien" class="flex flex-col gap-y-6">
    <div
      class="
        sm:flex
        lg:hidden
        xl:hidden
        md:flex
        flex-row
        items-center
        gap-x-4
        px-2
      "
    >
      <img src="/home/pricing.svg" alt="Praticiens" />
      <h1 class="text-2xl font-EffraM text-black">Praticiens</h1>
    </div>

    <div id="content">
      <div class="grid grid-cols-2 lg:grid-cols-3">
        <div class="hidden lg:block"></div>
        <div
          class="
            border border-t-4 border-r-0 border-b-0 border-solid
            b-praticien
            py-3
            px-1
            text-center
            font-EffraM
            text-lg
          "
        >
          Gratuit
        </div>
        <div
          class="
            border border-t-4 border-b-0 border-solid
            b-senior
            py-3
            px-1
            text-center
            font-EffraM
            text-lg
          "
        >
          PREMIUM <br />
          30 jours d'essai offerts
        </div>
      </div>
      <div class="grid grid-cols-2 lg:grid-cols-3 h-200">
        <div
          class="
            border border-r-0 border-solid border-grayTable
            hidden
            lg:flex
            justify-center
          "
        >
          <div class="flex flex-row items-center gap-x-4 px-2">
            <h1 class="text-2xl font-EffraM text-black">Praticiens</h1>
            <img src="/home/pricing.svg" alt="Praticiens" />
          </div>
        </div>
        <!----    Free pack ---->
        <div
          class="
            border border-r-0 border-solid border-grayTable
            flex flex-col
            py-6
          "
        >
          <div class="flex-1 flex justify-center">
            <h1 class="font-EffraM text-black text-base lg:text-3xl">
              0 MAD / Mois
            </h1>
          </div>
          <div class="flex justify-center">
            <!-- <button
              @click="
                () => {
                  this.$router.push({ name: 'newSingUp' });
                }
              "
              class="
                h-10
                bg-dokBlue-ultra
                text-white
                border-0
                py-2
                px-5
                focus:outline-none
                rounded
                font-EffraM
                cursor-pointer
                uppercase
                text-lg
              "
              aria-label="Choisir"
            >
              Choisir
            </button> -->
          </div>
        </div>
        <!----    850 DH pack ---->
        <div
          class="
            border border-solid border-grayTable
            flex flex-col
            gap-y-8
            pb-6
          "
        >
          <!-- <div class="flex-1 grid gap-x-0 grid-cols-1 lg:grid-cols-3">
             <div
              v-for="(item, index) in pack.prem"
              :key="index"
              class="flex flex-col items-center justify-center border-0 border-b-2 border-solid cursor-pointer"
              :class="
                item.choice
                  ? 'bg-dokBlue-lighter border-dokBlue-ultra'
                  : 'bg-transparent border-gray-400'
              "
              @click="setPack(index)"
            >
              <span id="title" class="font-EffraR text-base text-black">{{
                item.title
              }}</span>
              <span id="price" class="font-EffraM text-base text-grayaaa"
                >{{ item.price }} MAD/mois</span
              >
            </div> -->
          <!-- </div> -->
          <div class="flex-1 flex justify-center w-full py-8">
            <h1 class="font-EffraM text-black text-base lg:text-3xl">
              800 MAD / Mois
            </h1>
          </div>
          <div class="flex justify-center">
            <!-- <button
              @click="
                () => {
                  this.$router.push({ name: 'newSingUp' });
                }
              "
              class="
                h-10
                bg-dokBlue-ultra
                text-white
                border-0
                py-2
                px-5
                focus:outline-none
                rounded
                font-EffraM
                cursor-pointer
                uppercase
                text-lg
              "
              aria-label="Choisir"
            >
              Choisir
            </button> -->
          </div>
        </div>
      </div>
      <!-- Content Tablet max width: 886px -->
      <div
        class="lg:hidden xl:hidden sm:flex md:flex flex-col"
        v-for="(item, index) in pricing"
        :key="`particine-${index}`"
      >
        <div
          class="
            bg-sidebar
            flex flex-col
            py-4
            px-2
            border border-t-0 border-b-0 border-solid border-grayTable
          "
        >
          <div class="flex items-center gap-x-2 font-EffraM text-lg">
            {{ item.name }}
            <span class="w-[16px] h-[16px] overflow-hidden">
              <svg
                @click="item.tooltipIs = !item.tooltipIs"
                class="cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 16 18"
              >
                <g clip-path="url(#clip0)">
                  <path
                    stroke="#AAA"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8 15.167c3.682 0 6.666-2.985 6.666-6.666 0-3.682-2.984-6.667-6.666-6.667-3.682 0-6.667 2.985-6.667 6.667 0 3.681 2.985 6.666 6.667 6.666zM8 11.167V8.5M8 5.834h.007"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <path
                      fill="#fff"
                      d="M0 0H16V16H0z"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </span>
          </div>
          <div
            class="font-EffraR text-sm pb-2"
            style="color: #767676"
            v-show="item.tooltipIs"
          >
            {{ item.tooltip }}
          </div>
        </div>
        <div class="grid grid-cols-2 min-h-65">
          <div
            class="
              flex
              justify-center
              items-center
              border border-t-0 border-b-0 border-solid border-grayTable
            "
            :class="pricing.length - 1 === index ? 'border-b' : ''"
          >
            <svg
              v-if="item.free"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="none"
              viewBox="0 0 32 32"
            >
              <circle cx="16" cy="16" r="16" fill="#D8FFE3" />
              <path
                stroke="#1ED351"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M22.666 11L13.5 20.167 9.333 16"
              />
            </svg>
            <svg
              v-if="!item.free"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="none"
              viewBox="0 0 32 32"
            >
              <circle
                cx="16.001"
                cy="16"
                r="16"
                fill="#FD5B68"
                fill-opacity=".2"
              />
              <path
                stroke="#FD5B68"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 11L11 21M11 11l10 10"
              />
            </svg>
          </div>
          <div
            class="
              flex
              justify-center
              items-center
              border
              border-t-0
              border-b-0
              border-l-0
              border-solid
              border-grayTable
            "
            :class="pricing.length - 1 === index ? 'border-b' : ''"
          >
            <svg
              v-if="item.senior"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="none"
              viewBox="0 0 32 32"
            >
              <circle cx="16" cy="16" r="16" fill="#D8FFE3" />
              <path
                stroke="#1ED351"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M22.666 11L13.5 20.167 9.333 16"
              />
            </svg>
            <svg
              v-if="!item.senior"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="none"
              viewBox="0 0 32 32"
            >
              <circle
                cx="16.001"
                cy="16"
                r="16"
                fill="#FD5B68"
                fill-opacity=".2"
              />
              <path
                stroke="#FD5B68"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 11L11 21M11 11l10 10"
              />
            </svg>
          </div>
        </div>
      </div>
      <!-- Content Desktop -->
      <div
        class="hidden lg:grid grid-cols-3 min-h-65 pricing-item"
        v-for="(item, index) in pricing"
        :key="`desk-particine-${index}`"
      >
        <div
          class="
            px-3
            border-0 border-r border-l border-solid border-grayTable
            flex flex-col
            justify-center
          "
          :class="pricing.length - 1 === index ? 'border-b' : ''"
        >
          <div class="flex items-center gap-x-4 font-EffraM text-lg">
            {{ item.name }}
            <span class="w-[16px] h-[16px] overflow-hidden">
              <svg
                @click="item.tooltipIs = !item.tooltipIs"
                class="cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 16 18"
              >
                <g clip-path="url(#clip0)">
                  <path
                    stroke="#AAA"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8 15.167c3.682 0 6.666-2.985 6.666-6.666 0-3.682-2.984-6.667-6.666-6.667-3.682 0-6.667 2.985-6.667 6.667 0 3.681 2.985 6.666 6.667 6.666zM8 11.167V8.5M8 5.834h.007"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <path
                      fill="#fff"
                      d="M0 0H16V16H0z"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </span>
          </div>
          <div
            class="font-EffraR text-sm pb-2"
            style="color: #767676"
            v-show="item.tooltipIs"
          >
            {{ item.tooltip }}
          </div>
        </div>
        <div
          class="
            flex
            items-center
            justify-center
            gap-x-4
            px-3
            font-EffraR
            text-base
            font-medium
            border-0 border-r border-solid border-grayTable
          "
          :class="pricing.length - 1 === index ? 'border-b' : ''"
        >
          <span
            class="font-EffraR text-black text-lg font-medium"
            v-if="item.free !== '' && typeof item.senior !== 'boolean'"
          >
            {{ item.free }}
          </span>
          <svg
            v-if="item.free === true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 32 32"
          >
            <circle cx="16" cy="16" r="16" fill="#D8FFE3" />
            <path
              stroke="#1ED351"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M22.666 11L13.5 20.167 9.333 16"
            />
          </svg>
          <svg
            v-if="item.free === false"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 32 32"
          >
            <circle
              cx="16.001"
              cy="16"
              r="16"
              fill="#FD5B68"
              fill-opacity=".2"
            />
            <path
              stroke="#FD5B68"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 11L11 21M11 11l10 10"
            />
          </svg>
        </div>

        <div
          class="
            flex
            items-center
            justify-center
            gap-x-4
            px-3
            font-EffraR
            text-base
            font-medium
            border-0 border-r border-solid border-grayTable
          "
          :class="pricing.length - 1 === index ? 'border-b' : ''"
        >
          <span
            v-if="item.senior !== '' && typeof item.senior !== 'boolean'"
            class="font-EffraR text-black text-lg font-medium"
            >{{ item.senior }}</span
          >
          <svg
            v-if="item.senior === true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 32 32"
          >
            <circle cx="16" cy="16" r="16" fill="#D8FFE3" />
            <path
              stroke="#1ED351"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M22.666 11L13.5 20.167 9.333 16"
            />
          </svg>
          <svg
            v-if="item.senior === false"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 32 32"
          >
            <circle
              cx="16.001"
              cy="16"
              r="16"
              fill="#FD5B68"
              fill-opacity=".2"
            />
            <path
              stroke="#FD5B68"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 11L11 21M11 11l10 10"
            />
          </svg>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      pack: {
        prem: [
          { title: "1 mois", price: 1049, choice: false },
          { title: "12 mois", price: 850, choice: true },
          { title: "6 mois", price: 949, choice: false },
        ],
      },
      pricing: [
        {
          name: "Licence d'utilisation",
          tooltipIs: false,
          tooltip:
            "Licence utilisateur donnant le droit d'accès aux fonctionnalités de dok.ma pro à un praticien ou à un praticien et deux adjoint.e.s selon le plan choisi.",
          free: "1 praticien",
          junior: true,
          senior: "1 praticien et 2 adjoint.e.s",
        },
        {
          name: "Publication et référencement",
          tooltipIs: false,
          tooltip:
            "Amélioration du positionnement et de la visibilité de vos cabinets médicaux dans les pages de résultats des moteurs de recherche ou d'annuaires. ",
          free: true,
          junior: true,
          senior: true,
        },
        {
          name: "Agenda connecté personnalisable",
          tooltipIs: false,
          tooltip:
            "Programmation de votre agenda digital selon vos convenances.  Les patients peuvent ainsi se renseigner sur vos horaires d'ouverture, les créneaux disponibles, les motifs et les tarifs des consultations.",
          free: true,
          junior: true,
          senior: true,
        },
        {
          name: "Gestion des rendez-vous en ligne",
          tooltipIs: false,
          tooltip:
            "Création, report ou annulation de vos rendez-vous en ligne. Le patient est informé instantanément des changements effectués.",
          free: true,
          junior: true,
          senior: true,
        },
        {
          name: "Gestion des consultations en ligne",
          tooltipIs: false,
          tooltip:
            "Etablissement d'un compte rendu global de chaque consultation de façon détaillée et structurée en plusieurs étapes.",
          free: false,
          junior: true,
          senior: true,
        },
        {
          name: "Gestion de la patientèle",
          tooltipIs: false,
          tooltip:
            "Listage de tous les patients du cabinet. Le praticien peut via cet onglet accéder au dossier médical, éditer le carnet de santé et partager une consultation avec un confrère avec l'autorisation du patient.",
          free: false,
          junior: true,
          senior: true,
        },
        {
          name: "Dossier médical partagé des patients",
          tooltipIs: false,
          tooltip:
            "Profil général, carnet de santé, historique des consultations, note confidentielle et documents partagés, le tout regroupé pour faciliter l'accès au praticien à une information exhaustive et améliorer la prise en charge du patient.",
          free: false,
          junior: true,
          senior: true,
        },
        {
          name: "Suivi médical augmenté",
          tooltipIs: false,
          tooltip:
            "Activation et réception des retours du patient sur l'évolution de sa maladie après la consultation et conservation de l'historique des réponses et des actions entreprises par ce dernier.",
          free: false,
          junior: true,
          senior: true,
        },
        {
          name: "Messagerie sécurisée",
          tooltipIs: false,
          tooltip:
            "Système de messagerie instantanée où seul le praticien peut initier une conversation avec son patient.",
          free: false,
          junior: true,
          senior: true,
        },
        {
          name: "Ordonnances et certificats numériques",
          tooltipIs: false,
          tooltip:
            "Création, personnalisation, et génération en ligne de toutes sortes d'ordonnances et de certificats médicaux.",
          free: false,
          junior: true,
          senior: true,
        },
        {
          name: "Carnet de santé numérique",
          tooltipIs: false,
          tooltip:
            "Accès à l'ensemble des événements et des historiques médicaux du patient, notamment ses allergies, ses vaccinations, ses maladies chroniques, ses antécédents chirurgicaux...etc.  ",
          free: false,
          junior: true,
          senior: true,
        },
        {
          name: "Téléconsultation prépayée",
          tooltipIs: false,
          tooltip:
            "Consultation vidéo ou consultation à distance avec paiement à l'avance permet au patient de limiter ses déplacement et au praticien de réduire l'attente au cabinet.",
          free: false,
          junior: true,
          senior: true,
        },
        {
          name: "Partage sécurisé de documents",
          tooltipIs: false,
          tooltip:
            "Envoi et réception de fichiers en ligne entre le praticien et le patient en toute sécurité et confidentialité.",
          free: false,
          junior: true,
          senior: true,
        },
        {
          name: "Contrôle de l'assiduité des patients",
          tooltipIs: false,
          tooltip:
            "Tri des patients grâce à des statuts afin de déterminer les rendez-vous non honorés.",
          free: false,
          junior: true,
          senior: true,
        },
        {
          name: "Stockage de données au Maroc",
          tooltipIs: false,
          tooltip:
            "Données personnelles protégées et non diffusées conformément à la loi marocaine. Données d'usage, totalement anonymisées, utilisées pour produire des statistiques sur l’utilisation des services du site, afin de permettre l’amélioration des services et des fonctionnalités de dok.ma.",
          free: true,
          junior: true,
          senior: true,
        },
        {
          name: "Données sécurisées à l'aide de RBAC, TLS/SSL, Encryption at rest, CSFLE",
          tooltipIs: false,
          tooltip:
            "Accès aux données limité en fonction du rôle de l'utilisateur. Données cryptées pour être transportées sur une connexion réseau de confiance. Données cryptées sur le disque restent cryptées. Données sensibles cryptées à tout moment sur l'hôte de la base de données et en transit sur le réseau.",
          free: true,
          junior: true,
          senior: true,
        },
        {
          name: "Pas de frais d'installation",
          tooltipIs: false,
          tooltip: "Installation gratuite et accompagnement offert.",
          free: true,
          junior: true,
          senior: true,
        },
        {
          name: "Résiliation d'abonnement à tout moment",
          tooltipIs: false,
          tooltip:
            "Abonnement sans engagement, résiliable à tout moment. Les patients restants propriétaire de leurs données.",
          free: false,
          junior: true,
          senior: true,
        },
      ],
    };
  },
  methods: {
    setPack(index) {
      this.pack.prem.map((item, xIndex) => {
        if (xIndex === index) {
          item.choice = true;
        } else {
          item.choice = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.b-praticien::v-deep {
  border-top-color: #c4c4c4;
  border-left-color: #ebebeb;
  border-right-color: #ebebeb;
  border-bottom-color: #ebebeb;
}

.b-junior::v-deep {
  border-top-color: #fd5b68;
  border-left-color: #ebebeb;
  border-right-color: #ebebeb;
  border-bottom-color: #ebebeb;
}

.b-senior::v-deep {
  border-top-color: #297afb;
  border-left-color: #ebebeb;
  border-right-color: #ebebeb;
  border-bottom-color: #ebebeb;
}
</style>
